<template>
  <loader v-if="isLoading"/>
  <div v-else class="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
    <div class="kt-portlet">
      <div class="kt-portlet__head">
        <div class="kt-portlet__head-label">
          <h3 class="kt-portlet__head-title">Tariff Plans</h3>
        </div>
      </div>
      <div class="kt-portlet__body kt-portlet__body--fluid">
        <div class="kt-pricing-1" v-if="tariffs_list.length">
          <div class="discount-button-block">
            <button @click="onOpenDiscount('')" class="btn btn-success">I have a discount code</button>
          </div>
          <div class="kt-pricing-1__items row justify-content-md-center">
            <div v-for="(tariff, index) in tariffs_list"
                 :key="tariff.id"
                 :class="`kt-pricing-1__item col-lg-3 ${!tariff.is_active && 'disable'}`"
            >
              <div v-if="applayDiscount(tariff)" class="kt-badge kt-badge--inline kt-badge--danger discount__badge">
                discount {{ discount.value }}%
              </div>
              <div class="kt-pricing-1__visual">
                <div class="kt-pricing-1__hexagon1"></div>
                <div class="kt-pricing-1__hexagon2"></div>
                <span :class="`kt-pricing-1__icon kt-font-${tarrifs_ui_settings[index].color}`"><i
                    :class="`${tarrifs_ui_settings[index].icon}`"></i></span>
              </div>
              <span
                  :class="`kt-pricing-1__price ${applayDiscount(tariff) ? 'discount' : ''} `">{{ +tariff.price ? tariff.price : 'Free' }}
                <span v-if="+tariff.price"> USD</span>
              </span>
              <span v-if="applayDiscount(tariff)"
                    class="kt-pricing-1__price discount__price">{{ tariff.price - (tariff.price * discount.value) / 100 }} USD</span>
              <span v-else class="discount__price-placeholder"></span>
              <h2 class="kt-pricing-1__subtitle">{{ tariff.name }}</h2>
              <span class="kt-pricing-1__description">
                <span>{{ tariff.description }}</span>
              </span>
              <div class="kt-pricing-1__btn">
                <button
                    @click="onChangeTariff(tariff)"
                    :disabled="!tariff.is_active || (tariff.id === shop.plan && shop.has_active_charges ) || (isEditingPlan)"
                    :class="`btn btn-${tarrifs_ui_settings[index].color} btn-custom btn-pill btn-wide btn-uppercase btn-bolder`">
                  {{ +tariff.id === +shop.plan && shop.has_active_charges ? 'Current' : 'Pay Now' }}
                </button>
              </div>
            </div>

          </div>
        </div>
        <div v-if="!tariffs_list.length" class="nothing-found m-auto">
          <div class="nothing-found_content">
            <i class="far fa-search" style="font-size: 48px;"></i>
            <div>Nothing found</div>
          </div>
        </div>
        <b-modal ref="discount" hide-footer
                 size="sm"
                 :title="`Discount`">
          <div class="form-group"><label for="code">Discount code: </label>
            <input
                v-model="code"
                id="code" maxlength="20" required="required"
                class="form-control"></div>
          <button :disabled="!code.length" @click="onCheckCode()" class="btn btn-success">Apply</button>

        </b-modal>
      </div>
    </div>
  </div>
</template>
<script>
import {mapState, mapActions} from 'vuex'
import {LOAD, CHECK_CODE} from '../store/modules/tariffs/types';
import {CHANGE_PLAN} from '../store/modules/shop/types';
import {requestStatus} from '../services/index';
import Loader from '../components/Loader';

const CHARGE_TYPE_CHOICES = {
  any: 0,
  recurring: 1,
  annual: 2
};
export default {
  name: "tariffs",
  components: {Loader},
  data: () => ({
    code: '',
    isLoading: false,
    isEditingPlan: false,
  }),
  computed: {
    ...mapState({
      tariffs_list: state => state.tariffs.list,
      status: state => state.tariffs.status,
      shop_status: state => state.shop.status,
      discount: state => state.tariffs.discount,
      check_code: state => state.tariffs.check_code,
      shop: state => state.shop.shop,
      tariffs_redirect_url: state => state.shop.tariffs_redirect_url,
      send_plan_status: state => state.shop.send_plan_status,
    }),
    tarrifs_ui_settings: () => ([
      {color: 'brand', icon: 'fas fa-piggy-bank'},
      {color: 'success', icon: 'fas fa-star'},
      {color: 'danger', icon: 'fas fa-rocket'},
      {color: 'warning', icon: 'fas fa-gift'}
    ])
  },
  methods: {
    ...mapActions({
      load: `tariffs/${LOAD}`,
      checkCode: `tariffs/${CHECK_CODE}`,
      changeTariff: `shop/${CHANGE_PLAN}`,
    }),
    onCheckCode: function () {
      this.checkCode(this.code);
    },
    onCloseDiscount: function () {
      this.$refs['discount'].hide()
    },
    onOpenDiscount(value) {
      this.growth_tools = value;
      this.$refs['discount'].show()
    },
    onChangeTariff(plan) {
      let data = {
        "plan": plan.id
      };
      if (this.discount.code) {
        data.discount_code = this.discount.code;
      }
      this.changeTariff(data);
    },
    applayDiscount: function (tariff) {
      let res = false;
      if (!this.discount) return 0;
      switch (this.discount.charge_type) {
        case CHARGE_TYPE_CHOICES.any:
          res = true;
          break;
        case CHARGE_TYPE_CHOICES.recurring:
          if (tariff.is_recurring) {
            res = true;
          }
          break;
        case CHARGE_TYPE_CHOICES.annual:
          if (!tariff.is_recurring) {
            res = true;
          }
          break;
      }
      return res && +tariff.price
    },
  },
  created: function () {
    this.load();
  },
  watch: {
    tariffs_redirect_url(newValue) {
      window.location = newValue;
    },
    discount() {
      this.code = '';
      this.onCloseDiscount();
    },
    check_code(newValue) {
      if (newValue.status === requestStatus.error) {
        this.code = '';
        this.onCloseDiscount();
      }
    },
    status(newValue) {
      this.isLoading = newValue.load === requestStatus.loading;
    },
    shop_status(newValue) {
      this.isEditingPlan = newValue.change_plan === requestStatus.loading;
    }
  },
}
</script>

<style scoped>
@import "../assets/style/pricing-1.min.css";
</style>